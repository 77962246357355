import request from '@/utils/request'

export function getBoxRuleOfActivity (data) {
  return request({
    url: '/activity/getBoxRuleOfActivity',
    data
  })
}

export function updateBoxRuleOfActivity (data) {
  return request({
    url: '/activity/updateBoxRuleOfActivity',
    data
  })
}

export function getBoxEventDesc (data) {
  return request({
    url: '/activity/getBoxEventDesc',
    data
  })
}

export function updateBoxEventDesc (data) {
  return request({
    url: '/activity/updateBoxEventDesc',
    data
  })
}

export function getStatisticalBrief (data) {
  return request({
    url: '/activity/getStatisticalBrief',
    data
  })
}

export function getBoxOrderStatistical (data) {
  return request({
    url: '/activity/getBoxOrderStatistical',
    data
  })
}
