<template>
  <Layout>
    <div class="app-container">
      <el-tabs
        v-model="currentLang"
        type="card"
      >
        <el-tab-pane
          v-for="lang in dataLangList"
          :key="lang"
          :label="langMap[lang].name"
          :name="langMap[lang].value"
        >
          <ManagementForm
            ref="subForm"
            :lang="lang"
            :form="form[lang]"
            @validateFail="handleValidateFail"
          />
        </el-tab-pane>
        <el-button
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
      </el-tabs>
    </div>
  </Layout>
</template>

<script>
import ManagementForm from '@/views/TreasureBox/components/ManagementForm'
import { getBoxEventDesc, updateBoxEventDesc } from '@/api/TreasureBox'

const translateList2Map = (list) => {
  return list.reduce((map, item) => {
    map[item.value] = item
    return map
  }, {})
}

export const langList = [
  { name: '英语', value: 'en' },
  { name: '中文(繁体)', value: 'zh_tw' },
  { name: '马来', value: 'ms' },
  { name: '越南', value: 'vi' },
  { name: '泰语', value: 'th' },
  { name: '韩语', value: 'ko' },
  { name: '印尼', value: 'in' }
]

const langMap = translateList2Map(langList)

export default {
  name: 'Management',
  components: { ManagementForm },
  data () {
    return {
      langMap,
      dataLangList: [],
      currentLang: 'en',
      isValidateFail: false,
      form: {}
    }
  },
  created () {
    this.queryDataList()
  },
  methods: {
    queryDataList () {
      getBoxEventDesc({})
        .then(res => {
          if (res.code === 200) {
            this.dataLangList = Object.keys((res.data.list || []))
            this.form = res.data.list
            // Object.keys(this.form).forEach(lang => {
            //   this.form[lang].imagePath = this.form[lang].imagePath.replace(process.env.VUE_APP_RESO_URI, '')
            // })
          }
        })
    },
    // 校验同级表单时, 切换到第一个报错的表单项
    handleValidateFail (lang) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentLang = lang
      })
    },
    handleCheckAllForm () {
      return new Promise(resolve => {
        Promise.all(this.$refs.subForm.map(item => item.validate()))
          .then((valid) => {
            if (valid) {
              resolve()
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('校验未通过, 请检查表单后再试')
          })
      })
    },
    handleSubmit () {
      this.handleCheckAllForm()
        .then(() => {
          updateBoxEventDesc({
            list: this.form
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
              }
            })
        })
    }
  }
}
</script>

<style scoped>

</style>
