<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      @validate="handleValidate"
    >
      <!--      <el-form-item-->
      <!--        label="活动规则文案"-->
      <!--        prop="desc"-->
      <!--      >-->
      <!--        <Tinymce-->
      <!--          v-model="form.desc"-->
      <!--          style="margin-right: 2px"-->
      <!--          :height="200"-->
      <!--        />-->
      <!--      </el-form-item>-->
      <el-form-item
        label="活动入口设置"
      >
        <span style="margin-right: 10px">上线</span>
        <el-switch
          v-model="form.onlineStatus"
          :active-value="1"
          :inactive-value="2"
        />
      </el-form-item>
      <el-form-item
        label="入口 - 个人中心 - banner"
        prop="imagePath"
      >
        <UploadImage
          :file-list.sync="imageList"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Tinymce from '@/components/Tinymce/index'
import UploadImage from '@/components/UploadImage/UploadImage'
export default {
  name: 'ManagementForm',
  components: { UploadImage },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  data () {
    const coverValidator = (rule, value, callback) => {
      if (this.imageList.length) {
        return callback()
      } else {
        callback(new Error('必须上传banner'))
      }
    }

    return {
      imageList: [],
      rules: {
        // desc: [{ required: true, message: '必须填写活动规则文案' }],
        imagePath: [{ required: true, validator: coverValidator }]
      }
    }
  },
  created () {
    const unwatch = this.$watch(
      'form',
      function (form) {
        if (!form) return
        if (form.imagePath) {
          const fileExtension = form.imagePath.split('.').pop().toLowerCase()
          this.imageList = [{
            extend: fileExtension,
            key: form.imagePath,
            url: process.env.VUE_APP_CDN + form.imagePath
          }]
          if (unwatch) { unwatch() }
        }
      },
      { immediate: true }
    )
  },
  methods: {
    async validate () {
      return this.$refs.form.validate().then(valid => {
        if (valid) {
          if (this.imageList.length) {
            this.form.imagePath = this.imageList[0].key
          }
          return Promise.resolve()
        }
      })
    },
    // 用于向父级组件暴露校验失败信息
    handleValidate (field, valid) {
      if (!valid) {
        this.$emit('validateFail', this.lang)
      }
    }
  }
}
</script>

<style scoped>

</style>
